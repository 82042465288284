.plain-body {
  font-family: Plain, sans-serif;
  color: #141414;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  min-height: 100%;
  background-color: #fff;
}
.side-padding {
  position: static;
  padding-left: 40px;
  padding-right: 40px;
}
.container-new {
  max-width: 1245px;
  margin-right: auto;
  margin-left: auto;
}
.vertical-spacing.home-hero-spacing {
  padding-top: 150px;
}
.vertical-spacing {
  padding-bottom: 80px;
}
.margin-bottom-24 {
  margin-bottom: 24px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-bottom-16 {
  margin-bottom: 16px;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}
.padding-top-60 {
  padding-top: 60px;
}
.margin-bottom-48 {
  margin-bottom: 48px;
}
.margin-bottom-200 {
  margin-bottom: 200px;
}
.margin-bottom-8 {
  margin-bottom: 8px;
}
.nowrap {
  white-space: nowrap;
}
.heading-extralarge {
  font-size: 54px;
  line-height: 1.1;
  font-weight: 500;
}
.cta-sub-text {
  font-weight: 500;
}
.heading-large {
  font-size: 36px;
  line-height: 1.2;
  margin: 0;
  font-weight: 700;
}
.wf-section {
  margin-top: 80px;
}
.benefit-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  grid-column-gap: 40px;
}
.benefir-left,
.benefir-right {
  width: 50%;
}
.margin-bottom-16 {
  margin-bottom: 16px;
}

.benefits-para {
  font-size: 20px;
  font-weight: 500;
}
.image-100 {
  width: 100%;
}
.benefit-flex.reverse {
  flex-direction: row-reverse;
}

@media (max-width: 768px) {
  .benefit-flex {
    flex-wrap: wrap;
  }
  .benefir-left,
  .benefir-right {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .w-nav-link {
    padding: 10px;
    font-size: 15px;
  }
  .cta-button.ghost.w-nav-link {
    padding: 10px !important;
    font-size: 15px;
  }
  .heading-extralarge {
    font-size: 40px;
  }
  .features-grid {
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
  }
  .features-title {
    font-size: 18px;
  }
  .paragraph-large {
    font-size: 15px;
  }
  .features-points-section .vertical-spacing {
    padding-bottom: 10px;
  }

  .heading-large {
    font-size: 28px;
  }

  .features-testimonial-text-wrap {
    padding: 50px 0px;
  }
}

@media (max-width: 500px) {
  .benefits-para {
    font-size: 16px;
  }
}
