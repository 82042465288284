.faq-parent-wrap {
  max-width: 830px;
  margin-right: auto;
  margin-left: auto;
}
.faq-link-menu {
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #bdbdbd;
}
.faq-link-grid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
}
.faq-anchor-link {
  display: flex;
  margin-bottom: -3px;
  padding: 20px 2vw;
  justify-content: center;
  align-items: center;
  border-bottom: 5px solid transparent;
  color: #000;
  text-decoration: none;
  font-weight: 600;
}
.faq-parent {
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;
  padding: 60px 6vw;
}
.faq {
  margin-bottom: 32px;
}
.faq-question-wrap {
  display: flex;
  padding-bottom: 12px;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}
.MuiButtonBase-root.MuiAccordionSummary-root {
  border-bottom: 1px solid #bdbdbd;
}
.faq-arrow-wrap,
.faq-arrow-embed {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}
.page-padding {
  position: relative;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
}
.section__questions.background-black {
  color: #fafafa;
}
.background-black {
  background-color: #141414;
}
.container__large {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1360px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 5rem;
  flex-direction: column;
}
.faqs-text-wrap {
  display: flex;
  width: 100%;
  padding: 100px 0px;
  align-items: flex-start;
  border-top: 1px solid #fafafa;
  flex-direction: column;
  text-align: left;
}
.heading-lar {
  font-size: 36px;
  line-height: 1.2;
  margin: 0;
  font-weight: 700;
}
.faqs-para {
  font-size: 20px;
  font-weight: 600;
}
.faq-heading {
  font-weight: 800;
  text-align: center;
}
.faq-answer-wrap {
  overflow: hidden;
}
.faq-answer-2 {
  margin-top: 10px;
  font-size: 14px;
}
.MuiTab-textColorPrimary.Mui-selected {
  color: #70a340 !important;
  font-weight: 600 !important;
}
.PrivateTabIndicator-colorPrimary-2 {
  background-color: #70a340 !important;
}
.MuiAccordionSummary-root {
  padding: 0px !important;
}
.MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
  box-shadow: none;
}
.MuiAccordionSummary-root .MuiTypography-root.MuiTypography-body1 {
  font-weight: 500;
  font-size: 1rem !important;
}
.MuiAccordionDetails-root {
  padding: 8px 0px 16px !important;
}
.MuiTypography-root.MuiTypography-body1 {
  font-size: 14px !important;
}

.accTitle {
  margin-bottom: 30px;
}

@media (max-width: 500px) {
  .faq-heading {
    font-size: 32px;
  }

  .wf-section {
    margin-top: 40px;
  }
  .faq-link-menu {
    margin-bottom: 30px;
  }
  .faq-parent {
    padding: 10px;
  }
  .accTitle .heading-large {
    font-size: 24px;
  }
  .accTitle {
    margin-bottom: 10px;
  }
}
