.heading-extra {
  font-size: 100px;
  line-height: 1.1;
  font-weight: bolder;
  color: #70a340;
}
.book-demo {
  background-color: #141414;
  color: #ffffff;
}
.hero-form-wrapper {
  width: 100%;
  background-color: #fafafa;
  max-width: 100%;
  display: block;
  color: #141414;
}
.text .MuiInputBase-input.MuiInput-input {
  background: transparent;
  border: none;
  border-bottom: 2px solid #cad5e2;
  margin-bottom: 15px;
  padding-top: 0px;
}
.form-label {
  font-weight: 700;
}
