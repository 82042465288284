.feature-hero-section {
  text-align: center;
}
.features-hero-wrapper {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}
.features-grid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 80px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
}
.features-flex {
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
}
.features-title.drk {
  color: #141414;
}
.features-title {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;
}
.paragraph-large {
  margin-bottom: 0;
  color: #4f4f4f;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
}
.features-icon-wrap {
  width: 32px;
  min-width: 32px;
  margin-right: 20px;
}
.features-points-list {
  padding-left: 20px;
}
.features-points-list li {
  margin-bottom: 10px;
  font-weight: 500;
}
.features-testimonial-wrapper {
  display: flex;
}
.features-testimonial-text-wrap {
  display: flex;
  width: 100%;
  padding: 100px 40px;
  align-items: flex-start;
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  flex-direction: column;
}
.quote-icon {
  width: 50px;
  min-width: 50px;
  margin-bottom: 10px;
}
.feature-testimone-txt {
  font-size: 20px;
}
.feature-testimonial-para {
  font-size: 20px;
  font-style: italic;
}
.help-section {
  text-align: center;
  background-color: #fafafa;
  color: #141414;
}
