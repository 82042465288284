.side-padding {
  position: static;
  padding-left: 40px;
  padding-right: 40px;
}
.main-navbar {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: auto;
  z-index: 100;
  background-color: #fafafa;
  box-shadow: 0 4px 20px 0 rgb(159 174 187 / 16%);
  padding: 15px 0;
}

.headerLogo {
  width: 140px;
  height: auto;
  object-fit: contain;
}

.container-new.nav-container-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav__menu-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  color: #fafafa;
  line-height: 1.2;
  font-weight: 600;
}
.w-nav-menu {
  position: relative;
  float: right;
}
.nav-link-wrapper {
  display: inline-flex;
  margin-right: 20px;
  flex: 1;
}
.cta-button.ghost {
  border-color: #70a340;
  background-color: transparent;
  color: #70a340;
}
.cta-button {
  padding: 13px 31px 16px !important;
  border: 1px solid #000;
  border-radius: 2px;
  transition: 0.3s;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 700;
}
.w-nav-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.w-nav-link:hover,
.w-nav-link:focus {
  color: #70a340;
}

.nav-button-wrapper {
  display: inline-flex;
}

.main-navbar.w-nav .navbar-collapse.collapse {
  text-align: right;
}

.brand.w--current {
  background-color: transparent;
}
.w-nav-brand {
  position: relative;
  float: left;
  text-decoration: none;
  color: #333;
}
.cursor-pointer {
  cursor: pointer;
  display: block;
  transition: opacity 0.2s;
}
.nav-link {
  flex: 0 auto;
  color: #141414;
  font-size: 1rem;
  font-weight: 700;
  white-space: nowrap;
}
.w-nav-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  padding: 20px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.main-navbar.w-nav .navBtn {
  display: none;
}

.main-navbar.w-nav .navBtn:focus {
  box-shadow: none;
}
.brandlogoHeader span {
  font-size: 22px;
  font-family: "Playball";
  font-size: "22px";
  padding: "0 6px 0 0";
}

@media (min-width: 768px) {
  .main-navbar.w-nav .navbar-collapse.collapse {
    display: block;
  }
}

@media (max-width: 767px) {
  .main-navbar.w-nav .navBtn {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 20px;
    right: 20px;
    background: #f2f2f2;
    align-items: center;
    color: #575757;
  }

  .main-navbar.w-nav .nav-link-wrapper {
    width: 100%;
  }
  .nav-button-wrapper {
    display: flex;
  }

  /* .main-navbar.w-nav .navbar-collapse.collapse.show {
    position: fixed;
    height: 100vh;
    right: 0;
    top: 0;
    width: 50vw;
    background: #2d2d2d;
    padding: 30px;
    padding-top: 80px;
    z-index: -1;
  } */

  .nav-container-2 {
    flex-wrap: wrap;
  }

  /* .main-navbar {
    box-shadow: 0 4px 20px 0 rgb(52 52 52 / 40%);
  } */
  .w-nav-menu {
    width: 100%;
  }

  .main-navbar.w-nav .nav-link-wrapper {
    flex-wrap: wrap;
    margin: 30px 0 0;
  }

  .main-navbar.w-nav .nav-link-wrapper .w-nav-link {
    display: block;
    width: 100%;
    /* color: #fff; */
  }

  .cta-button.ghost.w-nav-link {
    width: 100%;
    text-align: center;
    margin-top: 15px;
  }
}

@media (max-width: 500px) {
  .brandlogoHeader {
    padding: 4px 0 0 0 !important;
    width: 100% !important;
  }
  .brandlogoHeader span {
    font-size: 15px;
  }
  .brandlogoHeader img {
    width: 28px;
  }
}
