.hs-submit .actions {
  margin-top: 35px;
  margin-bottom: 7px;
  padding: 17px 0px;
  text-align: left;
}
.hs-submit .actions .hs-button {
  white-space: pre-wrap;
  background: #70a340;
  border-color: #70a340;
  color: #fafafa;
  font-size: 12px;
  font-family: arial, helvetica, sans-serif;
  text-transform: unset !important;
}
.hs-button {
  margin: 0;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  position: relative;
  text-align: center;
  transition: all 0.15s linear;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  padding: 12px 24px;
}
