.hero-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 40px;
}
.hero-content-left,
.hero-content-right {
  width: 50%;
}
.heading-extralarge.new-h1 {
  font-size: 48px;
  font-weight: 600;
}
.typed-text {
  color: #70a340;
}
.first_name {
  display: block;
  font-weight: 500;
  line-height: 20px;
  padding-top: 0;
  margin-bottom: 4px;
  font-size: 14px;
  text-align: left;
}
.margin-bottom-24 p {
  margin-top: 0;
  margin-bottom: 10px;
  background-color: transparent;
  color: #4f4f4f;
  font-weight: 500;
}
.hero-image-wrapper img {
  width: 100%;
  height: auto;
  max-width: 450px;
  margin: 0 auto;
  display: block;
  object-fit: contain;
}

.benefit-image-wrap > img {
  width: 100%;
  height: auto;
  max-width: 450px;
  margin: 0 auto;
  display: block;
  object-fit: contain;
}

.cta-section.center-text {
  text-align: center;
}
.cta-section {
  background-color: #fafafa;
}
.vertical-spacing.cta-spacing {
  padding-top: 20px;
  padding-bottom: 20px;
}
.cta-wrapper {
  display: flex;
  align-items: center;
}
.cta-content-wrap.flex-centre {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cta-content-wrap {
  width: 100%;
}
.form-embed {
  width: 100%;
  max-width: 1000px;
}
.hbspt-form {
  position: static;
  border: none;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 172px;
  padding: 0;
  margin: 0;
  max-width: 100%;
  margin-bottom: 18px;
}
.hb-form {
  width: 100%;
}
.hs_firstname {
  width: 50%;
  float: left;
}
.testimonial-profile-flex {
  display: flex;
  align-items: flex-start;
}
.testimonee-profile {
  overflow: hidden;
  width: 60px;
  height: 60px;
  min-height: 60px;
  min-width: 60px;
  margin-right: 40px;
  border-radius: 50%;
}
.image-100.cover {
  height: 100%;
  object-fit: cover;
}
.testimonee-name {
  font-weight: 500;
}
.fred {
  font-weight: 600;
}
.help-section {
  text-align: center;
  background-color: #fafafa;
  color: #141414;
}

.ourBrandsWrapper,
.ourBrandsWrapper .brandsImgCnt {
  display: flex;
  align-items: center;
}

.ourBrandsWrapper {
  flex-direction: column;
  gap: 24px;
  padding: 40px 0px;
}
.ourBrandsWrapper .brandsImgCnt {
  gap: 48px;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
}

@media (max-width: 600px) {
  .hero-flex {
    flex-wrap: wrap;
  }
  .hero-content-left,
  .hero-content-right {
    width: 100%;
  }
  .vertical-spacing.home-hero-spacing {
    padding-bottom: 0;
  }
}

@media (max-width: 500px) {
  .heading-extralarge.new-h1 {
    font-size: 38px;
  }
  .heading-large {
    font-size: 24px;
  }

  .testimonee-profile {
    margin-right: 15px;
  }

  .benefits-section.wf-section {
    margin-top: 0;
  }
}
