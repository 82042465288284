.section__footer {
  display: flex;
  padding-left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #141414;
  border-top: 2px solid #9faebb;
  color: #fafafa;
}
.page-padding {
  position: relative;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
}
.footer-bottom-bar {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #313131;
}
.container__large {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1360px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 5rem;
  flex-direction: column;
}
.footer__grid {
  width: 100%;
  padding-bottom: 2em;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-areas: ".";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  border-top: 2px #9faebb;
}
.w-layout-grid {
  display: grid;
  grid-auto-columns: 1fr;
}
.footer__grid-wrapper {
  display: flex;
  margin-right: 15px;
  margin-left: 0;
  padding-top: 30px;
  flex-direction: column;
  align-items: flex-start;
}
.footer__heading {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 600;
  text-transform: uppercase;
}
.footer-link {
  display: block;
  margin-bottom: 8px;
  background-color: transparent;
  color: #878787;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
  text-decoration: none;
}

.footer-link:hover,
.footer-link:focus {
  color: #70a340;
}

.footer__text {
  margin-bottom: 12px;
  color: #878787;
  font-size: 1rem;
  line-height: 1.5;
}
.footer-field {
  margin-bottom: 18px;
}
.email {
  display: block;
  color: #ffffff;
  font-weight: 500;
  line-height: 20px;
  padding-top: 0;
  margin-bottom: 4px;
  font-size: 14px;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl::after,
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl::before {
  display: none;
}
.w-embed.w-script,
.textings {
  width: 100%;
}
.textings {
  margin-top: 0;
}
.hs-button {
  background: #70a340 !important;
  border-color: #70a340 !important;
  color: #ffffff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: arial, helvetica, sans-serif !important;
  padding: 8px 24px !important;
  text-transform: unset !important;
  border-radius: 3px !important;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  margin-top: 0px;
}
.footer-strip-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.footer-stripe-text {
  color: #cfcfcf;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 300;
}

@media (max-width: 500px) {
  .footer__grid {
    grid-template-columns: 1fr;
  }

  .section__footer .container__large {
    padding-top: 20px;
  }
}
