.firstname {
  color: #141414;
  font-family: arial, helvetica, sans-serif;
  font-size: 14px;
  width: 100%;
  text-align: left;
}
.hs-form-required {
  color: red;
}
.hs_firstname input {
  margin-right: 8px;
}
.texting {
  width: 100%;
}
.MuiInputBase-input.MuiInput-input {
  box-sizing: border-box;
  padding: 0 15px;
  min-height: 27px;
  max-width: 100%;
  background-color: #f5f8fa;
  height: 40px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  color: #33475b;
  border: 1px solid #cbd6e2;
  border-radius: 3px;
}
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  display: none;
}
.MuiInput-underline:before,
.MuiInput-underline:after {
  border-bottom: 0px !important;
}
